import "./index.css";

const Slider = () => (
  <section id="slider" class="hero-video min-vh-60 min-vh-md-100 with-header">
    <div class="overlay"></div>
    <video
      preload="auto"
      loop
      controls={false}
      autoPlay={true}
      playsInline
      muted
    >
      <source src="./videos/video-forest.mp4" type="video/mp4" />
      <source src="./videos/video-forest.webm" type="video/webm" />
    </video>

    <div
      class="slider-caption dark slider-caption-center justify-content-center"
      style={{ transform: "translate3d(0px, 0px, 0px)", opacity: "1" }}
    >
      <h2 data-animate="fadeInDown" class="fadeInDown animated">
        <a href="" class="standard-logo">
          <img
            src="./images/dronek-logo-icon-g.svg"
            alt="Dronek Logo"
            style={{
              height: "200px",
              marginTop: "50px"
            }}
          />
        </a>
      </h2>
      <h3  style={{ fontSize: "3rem"   }} class="pt-4 mt-3 mb-5 justify-content-center">
  <span data-rotate="fadeInDown"
          data-speed="2000" class="pyche"> <i></i> </span>
        {/* <span
          class="text-rotater nocolor "
          data-separator="|"
          data-rotate="fadeInUp"
          data-speed="2000"
        >
          <span class="t-rotate fw-bold font-body opm-large-word">
            Innovation|Performance|Simplicité|Disponibilité|Responsabilité|Confiance
          </span>
        </span> */}
      </h3>
    </div>
 <div className="center justify-content-center">
 <a
      class="zIndex one-page-arrow dark"
      style={{ marginTop: 10 }}
    >
      <i class="bi bi-chevron-down infinite animated fadeInDown text-white"
      style={{ fontSize: 30, marginTop: 100 }}
      ></i>
    </a>
 </div>
  </section>
);
export default Slider;