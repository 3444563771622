import React from "react";
import "./index.css";
import { CTA } from "../../components/CTA/CTA";

const About = () => {
  return (
    <>
      {/* Hero Start */}

      <div className="container my-5">
        <div className="row">
          <div className="col-md-6 text-center">
            <img
              src="./images/1 (7).JPG"
              className="img-fluid"
              style={{ boxShadow: "0 0 15px #eee" }}
              alt=""
            />
          </div>
          <div className="col-md-6 pt-3 mt-5">
            <p className="text-dark text-muted" style={{ pneHeight: 2 }}>
            DRONEK SARL est un cabinet de conseil spécialisé en agriculture,
              foresterie et agroforesterie. Avec DRONEK, vous bénéficiez d'une
              expertise inégalée dans le domaine du reboisement et de la
              restauration des écosystèmes. Nous sommes fiers de collaborer avec des
              entreprises soucieuses de leur empreinte environnementale, car
              nous croyons que la réussite commerciale peut aller de pair avec
              la préservation de notre planète. En choisissant DRONEK comme
              partenaire, vous rejoignez une communauté d'entreprises engagées
              dans la durabilité. Faites le choix de la qualité, de
              l'écoresponsabilité et de l'engagement environnemental en
              choisissant DRONEK comme fournisseur de plants pour votre
              production. Ensemble, cultivons un avenir durable et prospère.
            </p>
            <div className="container">
              <ul className="row text-center mt-5">
                <p className="col">
                  <i className="fa fa-check-circle fa-2x text-success me-2" />{" "}
                  <br />
                  <p className="text-primary">FORESTERIE </p>
                </p>
                <p className="col">
                  <i className="fa fa-check-circle fa-2x text-success me-2" />{" "}
                  <br />
                  <p className="text-primary">AGRICULTURE</p>
                </p>
                <p className="col">
                  <i className="fa fa-check-circle fa-2x text-success me-2" />{" "}
                  <br />
                  <p className="text-primary">TECHNOLOGIE</p>
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Hero End */}
      {/* About Start */}

      <div class="container text-center">
        <div class="row">
          <div class="col">
            <p class="fs-6 text-primary"> 7</p>
            <i>Années d'experiences</i>
          </div>
          <div class="col">
            <p class="fs-6 text-primary"> 12</p>
            <i>Projects Complète</i>
          </div>
          <hr />
        </div>
      </div>

      {/* Facts End */}
      {/* Team Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="mx-auto text-center mb-5" style={{ maxWidth: 800 }}>
            <h2 className="text-uppercase text-primary text-uppercase">
              <i>Notre équipe</i>
            </h2>
            <p className="text-dark">
            Notre équipe passionnée est
              déterminée à vous accompagner dans votre démarche écoresponsable,
              en créant un impact positif pour aujourd'hui et pour les
              générations futures.
            </p>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <div className="row g-0">
                <div className="col">
                  <div className="position-relative">
                    <img
                      className="img-fluid w-100"
                      src="images/Team/image ELVIS.jpg"
                      alt=""
                    />
                    <div
                      className="position-absolute start-0 bottom-0 w-100 py-3 px-4"
                      style={{ background: "rgba(52, 173, 84, .85)" }}
                    >
                      <h5 className="text-white">Kouacou Yao Elvis Franklin</h5>
                      <span className="text-white">
                        Fondateur-Expert Forestier
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="row g-0">
                <div className="col">
                  <div className="position-relative">
                    <img
                      className="img-fluid w-100"
                      src="images/Team/Image KAMBIRE.jpeg"
                      alt=""
                    />
                    <div
                      className="position-absolute start-0 bottom-0 w-100 py-3 px-4"
                      style={{ background: "rgba(52, 173, 84, .85)" }}
                    >
                      <h5 className="text-white">Kambire Sié Paul</h5>
                      <span className="text-white">Gérant</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="row g-0">
                <div className="col">
                  <div className="position-relative">
                    <img
                      className="img-fluid w-100 "
                      src="images/Team/IMG_0134.JPG"
                      alt=""
                    />
                    <div
                      className="position-absolute start-0 bottom-0 w-100 py-3 px-4"
                      style={{ background: "rgba(52, 173, 84, .85)" }}
                    >
                      <h5 className="text-white">Kouame Kouakou Donald</h5>
                      <span className="text-white">Chef Projet</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}
      <CTA />
    </>
  );
};

export default About;
