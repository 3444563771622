import React from 'react'
import './index.css'

export const Client = () => {
  return (
<section>
<div className="container text-center mb-5">
  <div className="title">
    <h2 className='text-uppercase display-6  mt-3 text-success'> <i>Des partenaires qui nous font confiance</i> </h2>
  </div>
  <div className="row mt-5">
    <div className="col">
      <img src="./images/clients/logo/agro-map-logo.png" alt="" className='img rounded'  width={120}/>
    </div>
    <div className="col">
    <img src="./images/clients/logo/conseill-cafe-cacao-logo.png" alt="" className='img rounded' width={120} />
    </div>
    <div className="col">
    <img src="./images/clients/logo/Olamlogo.png" alt="" className='img rounded' width={120}  />
    </div>
    <div className="col">
    <img src="./images/clients/logo/onf-international-logo.png" alt="" className='img rounded' width={120} />
    </div>
    <div className="col">
    <img src="./images/clients/logo/saco-logo.jpg" alt="" className='img rounded' width={130} height={70} />
    </div>
    <div className="col">
    <img src="./images/clients/logo/wcf-logo.png" alt="" className='img rounded' width={120} />
    </div>
  </div>
</div>  
</section>
  )
}
