import React from 'react'
import './index.css'

export const CTA = () => {
  return (
<div
  className="py-5 bg-primary c2a1"
>
  <div className="container">
    {/* Row */}
    <div className="row justify-content-center">
      <div className="col-md-7 text-center me-2">
        <h3 className="text-uppercase mb-3 text-white">
         <i>L’Humain et le respect de
          l’environnement sont au cœur de notre
          entreprise.</i>
        </h3>
        <p className="text-white">
        DRONEK est une société spécialisée en agriculture et en foresterie dont la mission principale est de veiller sur les ressources naturelles et lutter contre les menaces écologiques.
Partenaires écoresponsable, nous nous engageons à réaliser des prestations dans le plus grand respect des normes écologiques.
        </p>
        <a
          className="btn btn-light text-uppercase"
          href="/contact"
        >
          <i>Contactez-Nous</i>
        </a>
      </div>
    </div>
    {/* Row */}
  </div>
</div>

  )
}
