import React from 'react'
import SimpleMap from './simpleMap/index'

const Contact = () => {
  return (
    <>
  <div class="container-fluid py-5">
    <div class="container">
      <div class="mx-auto text-center mb-5" style={{ maxWidth: 500 }}>
        <img src="./images/agri.svg" alt="" style={{ width : 180 }} />
        <h2 class="text-primary text-uppercase"><i>Profitez de notre expertise</i></h2>
      </div>
      <div class="row g-0">
        <div class="col-lg-7">
          <div class="bg-primary h-100 p-5">
          <SimpleMap />
          </div>
        </div>
        <div class="col-lg-5">
          <div class="bg-secondary h-100 p-5">
            <h4 class="text-white mb-4 "><i></i></h4>
            <div class="d-flex mb-4">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: 60, height: 60 }}
              >
                <i class="bi bi-geo-alt fs-4 text-white" />
              </div>
              <div class="ps-3">
                <h6 class="text-white"><i>Notre bureau</i></h6>
                <span class="text-white">
                Cocody en face  <br />
         de l'entrée principale <br />
        de l’hôtel Palm Club
                </span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: 60, height: 60 }}
              >
                <i class="bi bi-envelope-open fs-4 text-white" />
              </div>
              <div class="ps-3">
                <h6 class="text-white"><i>Envoyez-nous un mail</i></h6>
                <span class="text-white"><a href="mailto:info@dronek.net" class='text-white'>info@dronek.net</a></span>
              </div>
            </div>
            <div class="d-flex">
              <div
                class="bg-primary rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: 60, height: 60 }}
              >
                <i class="bi bi-phone-vibrate fs-4 text-white" />
              </div>
              <div class="ps-3">
                <h6 class="text-white"><i>Appelez - nous</i></h6>
                <a class="text-white" href='tel:+225 07 07 73 22 64'>+225 07 07 73 22 64</a><br />
                <span class="text-white"><a class="text-white" href='tel:+225 27 21 51 41 49'>+225 27 21 51 41 49</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Contact End */}
</>

  )
}

export default Contact