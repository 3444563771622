import React from "react";
import "./index.css";
import { CTA } from "../../components/CTA/CTA";

export const Realisation = () => {
  return (
    <>
      <section className="px-5 py-6 py-xxl-10 bg-primary">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-black">
              <h2 className="text-uppercase text-white mt-3">
                <i>NOS RÉALISATIONS</i>
                </h2>
              <p className="text-white mt-3">
                Dronek se distingue par une solide expertise technique et une 
                maîtrise des innovations technologiques, lui permettant de 
                fournir des services efficaces <br /> et performants dans  les secteurs 
                de la foresterie et de l'agriculture.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-blog d-flex align-items-center mt-5">
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-lg-6">
              <div className="text-center mb-4">
                <h2 className="text-uppercase blog-title position-relative text-primary mt-4">
                  <i>Nos Réalisations</i>
                </h2>
                <p className="text-muted ">
                  Découvrez certains de nos incroyables réalisations à travers
                  le pays
                </p>
              </div>
            </div> */}
          </div>
          {/*end row*/}
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="./images/agriculture2.jpg"
                    alt="img-1"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p className="">
                    Contribution à la reforestation dans la filière café cacao
                    (Phase 3)
                  </p>
                  <p className="text-primary mb-0">
                    Conseil Café Cacao, Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Janvier - Octobre
                    2023
                  </p>
                  <p className="text-muted mt-2">
                    Production de 300 000 plants d’ombrage pour le compte des
                    coopératives de la filière café cacao
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="./images/IMG-20210529-WA0022.jpg"
                    alt="img-3"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p className="mb-3">
                    Production, transport et planting d’arbres d’ombrages
                  </p>
                  <p className="text-primary">AGRO-MAP</p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> 07 - 25 Octobre
                    2021
                  </p>
                  <p className="text-muted mt-2">
                    Production et fournitures des plants forestiers. Reboisement
                    de 20ha de terre dont 7ha en association avec des cultures
                    vivrières et création d’une parcelle agroforestière
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="./images/IMG-20200613-WA0001.jpg"
                    alt="img-3"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >Fourniture de rejets de bananiers</p>
                  <p className="text-primary">
                    Conseil Café Cacao, Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Juin 2022
                  </p>
                  <p className="text-muted ">
                    Fourniture de 35 839 rejets de bananiers
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="./images/IMG-20210529-WA0012.jpg"
                    alt="img-1"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >
                    Contribution à la reforestation dans la filière café cacao
                    (Phase 2)
                  </p>
                  <p className="text-primary">
                    Conseil Café Cacao, Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Janvier - Octobre
                    2022
                  </p>
                  <p className="text-muted mb-0">
                    Production de 1 500 000 plants d’ombrage pour le compte des
                    coopératives de la filière café cacao
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="images/IMG-20210615-WA0003.jpg"
                    alt="img-2"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      {" "}
                      Prestataire unique{" "}
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >Planting et prise de coordonnées GPS</p>{" "}
                  <br />
                  <p className="text-primary">
                    Société Africaine de Cacao,Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Juin - Août 2022
                  </p>
                  <p className="text-muted mb-0">
                    Production, planting et prise de coordonnées GPS de 156 056
                    plants d’arbres d’ombrages dans des parcelles de cacao des
                    producteurs de coopératives affiliés à la SACO
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="images/agriculture.jpg"
                    alt="img-3"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique{" "}
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >
                    Production et livraison plants d’arbres forestiers à des
                    coopératives partenaires d’OUTSPAN IVOIRE
                  </p>{" "}
                  <br />
                  <p className="text-primary">
                    OLAM (OUTSPAN IVOIRE),Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Juin - Août 2022
                  </p>
                  <p className="text-muted mb-0">
                    Production et livraison de 108 006 plants d’arbres
                    forestiers des sociétés coopératives partenaires d’OUTSPAN
                    IVOIRE OLAM (OUTSPAN IVOIRE)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="./images/agriculture.jpg"
                    alt="img-1"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >
                    Production de plants d’avocatiers pour le compte des
                    coopératives partenaires à la SACO
                  </p>
                  <br />
                  <p className="text-primary">
                    Société Africaine de Cacao, Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Novembre 2021 -
                    Mai 2022
                  </p>
                  <p className="text-muted mb-0">
                    Production de 200 000 plants fruitiers pour le compte des
                    coopératives partenaires à la SACO
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="images/foret2.jpg"
                    alt="img-2"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Membre du consortium{" "}
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >
                    Collecte de données de référence de l’agroforesterie et
                    dégradation des forêts
                  </p>{" "}
                  <br />
                  <p className="text-primary">
                    Deutsche Gesellschaft für Internationale Zusammenarbeit
                    (GIZ), Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Septembre 2021-
                    Mars 2022
                  </p>
                  <p className="text-muted mb-0">
                    Collecte de données sur les mesures des arbres et calcul de
                    biomasse
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="images/fourniture-de-plants.jpg"
                    alt="img-3"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >
                    Contribution à la reforestation dans la filière café cacao
                    (Phase 1)
                  </p>{" "}
                  <br />
                  <p className="text-primary">
                    Conseil Café Cacao, Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Janvier - Octobre
                    2021
                  </p>
                  <p className="text-muted mb-0">
                    Production de 3 000 000 plants d’ombrage pour le compte des
                    coopératives de la filière café cacao
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="./images/IMG-20210529-WA0022.jpg"
                    alt="img-1"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >
                    Contribution à la reforestation dans la filière café cacao
                    (Phase 3)
                  </p>{" "}
                  <br />
                  <p className="text-primary">
                    Conseil Café Cacao, Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Janvier - Octobre
                    2023
                  </p>
                  <p className="text-muted mt-2">
                    Production de 300 000 plants d’ombrage pour le compte des
                    coopératives de la filière café cacao
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="./images/IFFN.jpg"
                    alt="img-3"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Consultant unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >
                    Inventaire forestier et faunique national
                  </p>
                  <p className="text-primary">
                    Office National de la Forêt, Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Janvier 2019 -
                    Avril 2021 
                  </p>
                  <p className="text-muted mb-0">
                    Inventaire des animaux sauvages sur le territoire national
                  </p>
                </div>
              </div>
              
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-box card border-0 ">
                <div className="position-relative overflow-hidden">
                  <img
                    src="./images/IMG-20210529-WA0012.jpg"
                    alt="img-3"
                    className="img-fluid rounded-top"
                  />
                  <div className="blog-tag">
                    <span className="badge bg-primary fw-normal">
                      Prestataire unique
                    </span>
                  </div>
                </div>
                <div className="card-body blog-content text-center p-3">
                  <p >
                    Production, transport et planting d’arbres d’ombrages
                  </p>
                  <p className="text-primary">
                    Conseil Café Cacao, Côte d’Ivoire
                  </p>
                  <p>
                    <i className="fa fa-calendar  me-2" /> Mars - Août 2019
                  </p>
                  <p className="text-muted mb-0">
                    Fourniture et transport de 70 000 plants, reboisement et
                    création de parcelle agroforestières Foncier Foresterie
                    Agriculture (FOA)
                  </p>
                </div>
              </div>
              {/*end blog-box*/}
            </div>
          </div>

          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      <CTA />
    </>
  );
};
