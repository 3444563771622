import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => (
  <div style={{
    color: 'white', 
    background: 'grey',
    padding: '15px 10px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)'
  }}>
    {text}
  </div>
);

export default function SimpleMap(){
  const defaultProps = {
    center: {
      lat: 5.354153281268519, 
      lng:  -4.0035128896594
    },
    zoom: 15
  };

  return (
    // Important! Always set the container height explicitly
    <div className="reduce" style={{ height: '50vh', position: 'relative'}}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={5.354121235191493} 
          lng={-4.0035128896594}
          text="Dronek"
        />
      </GoogleMapReact>
    </div>
  );
}