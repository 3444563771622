import "./index.css";

export default function index() {
  return (
    <>
      {/*====================== Start Blog Section ======================*/}
      <section className="speedyui speedyui-blog bg-light py-4 py-md-5">
        <div className="container">
          <div className="row mb-4 mb-md-5 justify-content-center text-center">
            <div className="col-lg-8 mb-4">
              <h2 className="text-primary text-uppercase">
                <i>Nos Actualités</i>
              </h2>
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card">
                <img
                  src="./images/d3.jpeg"
                  className="card-img-top"
                  height={190}
                  alt="..."
                />
                <div className="card-body">
                  <a
                    href="https://www.minutes-eco.com/news/2727-expertise-une-entreprise-ivoirienne-devoile-son-savoir-faire-en-agro-foresterie-et-en-agriculture-durable"
                    className="card-title d-block mb-2 text-uppercase"
                    style={{ fontSize: 13 }}
                    target="_blank"
                    rel="noreffer"
                  >
                    <i>
                      Expertise - Une entreprise ivoirienne devoile son
                      savoir-faire en agro-foresterie et en agriculture durable
                    </i>
                  </a>
                  <a className="d-flex align-items-center text-decoration-none">
                    <span className="icon d-inline-flex me-1">
                      <i className="bi bi-calendar-event"></i>
                    </span>{" "}
                    09 Octobre 2023
                  </a>
                  <p className="card-text mt-1" style={{ fontSize: 13 }}>
                    L'entreprise ivoirienne Dronek, specialiste en foresterie,
                    agroforesterie et agriculture durable, a exposé son
                    expertise dans le cadre du Salon internationale de
                    l'agriculture et des ressources humaines qui s'est tenu du
                    29 septembre au samedi 8 octobre 2023, au parc d'exposition
                    d'Abidjan.
                    Le directeur genéral Elvis Yao Kouacou, a partagé le savoir-faire 
                    de l'entreprise tant avec les visiteurs qu'avec les autres exposants.
                  </p>
                </div>
              </div>
              {/*.card*/}
            </div>
   {/*.col-grid*/}
   <div className="col-lg-4 col-md-6 mb-4">
              <div className="card">
                <img
                  src="./images/d1.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <a
                    className="card-title d-block mb-2 text-uppercase"
                    style={{ fontSize: 13 }}
                  >
                    <i>
                      SARA 2023 <br />
                      Elvis Yao Kouacou (Dg de Dronek) a présenté les avantages
                      de l’agroforesterie
                    </i>
                  </a>
                  <a className="d-flex align-items-center text-decoration-none">
                    <span className="icon d-inline-flex me-1">
                      <i className="bi bi-calendar-event"></i>
                    </span>{" "}
                    4 octobre 2023
                  </a>
                  <p className="card-text mt-1" style={{ fontSize: 13 }}>
                    Salon international de l’agriculture et des ressources
                    animales tenu du 9 au 8 octobre 2023 au Parc des expositions
                    d’Abidjan à Port-Bouët, une entreprise ivoirienne
                    spécialisée en foresterie, agroforesterie et agriculture
                    durable, Dronek, a présenté certaines de ces missions.
                  </p>
                </div>
              </div>
              {/*.card*/}
            </div>
            {/*.col-grid*/}
            {/*.col-grid*/}
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card">
                <img
                  src="./images/d2.webp"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <a
                    href="https://www.linfodrome.com/societe/89110-restauration-du-couvert-forestier-une-structure-appuie-le-marathon-du-planting-d-arbres-forestiers-a-abengourou"
                    className="card-title d-block mb-2 text-uppercase"
                    style={{ fontSize: 13 }}
                    target="_blank"
                    rel="noreffer"
                  >
                    <i>
                      RESTAURATION DU COUVERT FORESTIER : UNE STRUCTURE APPUIE
                      LE "MARATHON DU PLANTING D’ARBRES FORESTIERS" À ABENGOUROU
                    </i>
                  </a>
                  <a className="d-flex align-items-center text-decoration-none">
                    <span className="icon d-inline-flex me-1">
                      <i className="bi bi-calendar-event"></i>
                    </span>{" "}
                    2 Août 2023
                  </a>
                  <p className="card-text mt-1" style={{ fontSize: 13 }}>
                    L’entreprise d’agroforesterie Dronek a pris part, le 2 août
                    2023 à Abengourou (capitale de l’Indénié-Djuablin), à la
                    deuxième édition de l'opération « Marathon du planting
                    d’arbres forestiers » organisée par la direction générale du
                    ministère des Eaux et Forêts.
                  </p>
                </div>
              </div>

              {/*.card*/}
            </div>
         
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
    </>
  );
}
