import React from 'react'
import Logo from '../../Dronek-logo-g.png'
import "./index.css"

const Header = () => {
  return (
    <>
  {/* Topbar Start */}
  <div className="container-fluid px-5 d-none d-lg-block">
    <div className="row gx-5 py-3 alig000n-items-center">
      <div className="col-lg-3 mt-3">
        <div className="d-flex align-items-center justify-content-start ">
          <i className="bi bi-phone-vibrate fs-1 text-primary me-2" />
          <a href="tel:+225 07 07 73 22 64"><i>+225 07 07 73 22 64</i></a>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="d-flex align-items-center justify-content-center">
          <a href="/" className="navbar-brand ms-lg-5">
            <h1 className="m-0 display-4 text-primary">
              <img src={Logo} alt="" className='img-f' />
            </h1>
          </a>
        </div>
      </div>
      <div className="col-lg-3 mt-4">
        <div className="d-flex align-items-center justify-content-end">
        <a
                  className="btn btn-secondary btn-square rounded-circle me-2"
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/Dronek.CI/"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  className="btn btn-secondary btn-square rounded-circle me-2"
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/dronek"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
                <a
                  className="btn btn-secondary btn-square rounded-circle"
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="mailto:info@dronek.net"
                >
                  <i className="fa fa-envelope" />
                </a>
        </div>
      </div>
    </div>
  </div>
  {/* Topbar End */}
  {/* Navbar Start */}
  <nav className="navbar navbar-expand-lg bg-primary navbar-dark shadow-sm py-3 py-lg-0 px-3 px-lg-5">
    <a href="/" className="navbar-brand d-flex d-lg-none">
      <img src="./images/logooo.png" alt="" width={110} />
    </a>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav mx-auto py-0">
        <a href="/" className="nav-item nav-link me-2">
        <i>Accueil</i>
        </a>
        <a href="/services" className="nav-item nav-link me-3">
          <i>Services</i>
        </a>
        <a href="/realisations" className="nav-item nav-link me-3">
          <i>Réalisations</i>
        </a>
        <a href="/about" className="nav-item nav-link me-3">
          <i>À propos</i>
        </a>
        <a href="/contact" className="nav-item nav-link me-3">
          <i>Contact</i>
        </a>
      </div>
    </div>
  </nav>
  {/* Navbar End */}
</>

  )
}

export default Header