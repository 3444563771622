import React from "react";
import Slider from "../Slider/index";
import { Client } from "../client/Client";
import "./Acceuil.css";
import { CTA } from "../../components/CTA/CTA";
import Blog from "../../components/blog-home/index";

const Acceuil = () => (
  <>
    <Slider />
    <div className="container-fluid about pt-5 mb-5">
      <div className="container">
        <div className="row gx-5">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <div className="d-flex h-80 pt-0">
              <img
                className="img-fluid mt-auto mx-auto rounded-2"
                src="./images/drone.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 pb-5" style={{ marginTop: 100  }}>
            <div className="mb-3 pb-2">
              <h2 className="text-uppercase title text-primary text-uppercase">
                <i>Qu’est ce que Dronek ?</i>
              </h2>
            </div>
            <p className="text-dark" style={{ pneHeight: 2 }}>
              DRONEK est une entreprise de droit ivoirien
                créée en 2017 par un Ingénieur forestier diplômé
                de l'Institut National Polytechnique (INP-HB) de
                Yamoussoukro.
                DRONEK s'appuie sur une solide expertise
                technique et une maîtrise des innovations
                technologiques pour proposer des prestations
                efficaces et efficientes dans la foresterie et
                l'agriculture. L'équipe de DRONEK est composée
                d'experts aux compétences variées, et dispose
                de moyens matériels et technologiques adaptés
                pour mener à bien chaque projet.
            </p>
            <a
              className="text-uppercase btn btn-primary-1 text-center mt-3"
              href="/about"
            >
              <i>En savoir plus</i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <section className="section-services">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-10 col-lg-8">
            <div className="header-section">
              <h2 className="text-uppercase title-1 text-white">
                <i>Pourquoi nous choisir ?</i>
              </h2>
              <p className="text-white mt-4">
                DRONEK propose une large gamme de services en foresterie,
                agroforesterie, gestion de l'environnement et en agriculture.
                Dronek vous accompagne dans vos projets d’innovations
                technologiques liées à l’agriculture. <br /> Notre technologie
                permet d’alléger considérablement le travail de terrain par
                l’élimination de l’échantillonnage et la prise de données
                manuelles qui peut représenter un travail de longue haleine.
              </p>
            </div>
          </div>
        </div>
        <div className="container text-center mt-3 ">
          <div className="row">
            <div className="col">
              <i className="fa fa-check-circle fa-2x  text-white" />
              <h6 className="text-white mt-2 mb-3">
                <i>COLLECTE</i>
              </h6>
            </div>
            <div className="col">
              <i className="fa fa-check-circle fa-2x text-white" />
              <h6 className="text-white mt-2   mb-3 ">
                <i>TRAITEMENT</i>
              </h6>
            </div>
            <div className="col">
              <i className="fa fa-check-circle fa-2x text-white" />
              <h6 className="text-white mt-2   mb-3 ">
                <i>EXPLOITATION</i>
              </h6>
            </div>
            <div className="col">
              <i className="fa fa-check-circle fa-2x text-white" />
              <h6 className="text-white mt-2   mb-3 ">
                <i>LIVRAISON</i>
              </h6>
            </div>
          </div>
          <a
            className="text-uppercase btn btn-light text-center mt-5"
            href="/services"
          >
            <i>En savoir plus</i>
          </a>
        </div>
      </div>
    </section>
    <section className="bg-light py-5 py-xl-8">
      <div className="container overflow-hidden">
        <div className="row gy-5 gy-lg-0 align-items-lg-center justify-content-lg-between">
          <div className="col-12 col-lg-6 order-1 order-lg-0">
            <h2 className="text-uppercase solution mb-3 text-success">
              <i>
                Des solutions technologiques innovantes pour optimiser les
                opérations agricoles
              </i>
            </h2>
            <p className="mb-4 text-dark">
            DRONEK vous accompagne dans vos projet d’innovation technologique 
            liées à l’agriculture. Notre technologie permet d’alléger 
            considérablement le travail de terrain par l’élimination de 
            l’échantillonnage et la prise de
             données manuelles qui peut représenter un travail de longue haleine.
             Nous utilisons la technologie dans la pratique de l'agroforesterie
              en ayant recours aux drones pour la collecte d'informations 
             relatives à la mise en place de systèmes agroforestiers de pointe
            </p>
            <div className="d-grid gap-2 d-sm-flex">
              <a
                type="button"
                href="/realisations"
                className="btn btn-primary-1 text-uppercase"
              >
                <i>En savoir plus</i>
              </a>
            </div>
          </div>
          <div
            className="col-12 col-lg-5 text-center"
            style={{ background: "white" }}
          >
            <div className="position-relative">
              <div className="bsb-circle border border-4 border-warning position-absolute top-10 end-0 z-1"></div>
              <img
                className="img-fluid position-relative z-2"
                loading="lazy"
                src="./images/SpxP.gif"
                alt=""
                height={100}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Blog />
    <CTA />
    <Client />
  </>
);

export default Acceuil;

