import { BrowserRouter, Routes,Route } from "react-router-dom";
import About from './pages/About/About';
import Acceuil from './pages/home/Acceuil';
import Contact from './pages/contact/Contact';
import Services from './pages/service/Services'
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { Realisation } from "./pages/Realisation/Realisation";



function App() {
  return (
    <BrowserRouter>
    <Header />
    <Routes>
        <Route exact path="/" element={<Acceuil />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/realisations" element={<Realisation />} />
      </Routes>
      <Footer />
  </BrowserRouter>
  );
}

export default App;
