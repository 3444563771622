import React from "react";
import "./index.css";
import { CTA } from "../../components/CTA/CTA";

const Services = () => {
  return (
    <>
      <div className="static-slider1 py-4">
        <div className="container">
          <div className="row">
            {/* Column */}
            <div className="col-md-7 align-self-center">
              <h2 className="text-uppercase title text-primary">
                <i>Dronek propose plusieurs services</i>
                <a class="text-success-gradiant typewrite" data-period="200" />
              </h2>
              <p className="text-muted mt-5">
                Dronek se distingue par une solide expertise technique et une
                maîtrise des innovations technologiques, lui permettant de
                fournir des services efficaces et performants dans les secteurs
                de la foresterie et de l'agriculture. <br /> L'équipe de Dronek
                est composée d'experts aux compétences diversifiées, équipés de
                ressources matérielles et technologiques adaptées pour mener à
                bien chaque projet.
              </p>
              <a
                className="btn btn-success-gradiant btn-md btn-arrow mt-3 text-black border-0"
                href="/contact"
              >
                <a className="text-uppercase btn btn-primary-1">
                  <i>Contactez-Nous</i>
                </a>
              </a>
            </div>
            {/* Column */}
            <div className="col-md-5 mt-4">
              <img
                src="./images/1 (4).JPGx"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <section className="bg-primary">
        <div class="container">
          <div class="row justify-content-evenly">
            <div class="col-md-5">
              <img
                src="./images/1 (2).JPG"
                className="img-fluid"
                alt=""
                style={{ marginTop:   0 }}
              />
            </div>
            <div className="col-sm-7 align-self-center">
              <h2 className="text-uppercase mt-3 text-white">
                <img src="./images/foret.svg" width={45} className="me-1" />{" "}
                <i>Foresterie</i>
              </h2>
              <p className="text-white">
                En foresterie, Dronek relève le défi d’un monde en mutation.
                Grâce à notre équipe de techniciens, de pépiniéristes et d’un
                répertoire de prestataires de qualité dans le domaine rural,
                nous proposons de nombreux services dans l’accompagnement des
                projets d’agroforesterie, de reforestation et d’aménagement des
                forêts.
              </p>
              <ul className="list-style-none pl-0 mt-0">
                <p className="my-2">
                  <a className="mr-2">
                    <i className="fa fa-check-circle text-white fa-1x" />
                  </a>{" "}
                  <a className="text-white">
                    <i>
                      FORMATIONS AUX MÉTIERS FORESTIERS (PÉPINIÉRISTES,
                      SYLVICULTEURS, AMÉNAGISTES FORESTIERS)
                    </i>
                  </a>
                </p>
                <p className="my-2">
                  <a className="mr-2">
                    <i className="fa fa-check-circle text-white fa-1x" />
                  </a>{" "}
                  <a className="text-white">
                    <i>
                      PRODUCTION DE PLANTS MARAICHERS PAR LA MISE EN PLACE DE
                      PÉPINIÈRES
                    </i>
                  </a>
                </p>
                <p className="my-2">
                  <a className="mr-2">
                    <i className="fa fa-check-circle text-white fa-1x" />
                  </a>{" "}
                  <a className="text-white">
                    <i>INVENTAIRE FORESTIER ET FAUNIQUE</i>
                  </a>
                </p>
                <p className="my-2">
                  <a className="mr-2">
                    <i className="fa fa-check-circle text-white fa-1x" />
                  </a>{" "}
                  <a className="text-white">
                    <i>SUIVI DE REBOISEMENT</i>
                  </a>
                </p>
                <p className="my-2">
                  <a className="mr-2">
                    <i className="fa fa-check-circle text-white fa-1x" />
                  </a>{" "}
                  <a className="text-white">
                    <i>PRODUCTION DE COMPOST</i>
                  </a>
                </p>
                <p className="mb-2 mt-0 mt-md-2">
                  <a className="mr-2">
                    <i className="fa fa-check-circle text-white fa-1x" />
                  </a>{" "}
                  <a className="text-white">
                    <i>TÉLÉDÉTECTIONS ET CARTOGRAPHIE FORESTIÈRE</i>
                  </a>
                </p>
                <p className="my-2">
                  <a className="mr-2">
                    <i className="fa fa-check-circle text-white fa-1x" />
                  </a>{" "}
                  <a className="text-white">
                    <i>
                      PRODUCTION DE PLANTS FORESTIERS ET FRUITIERS PAR LA MISE
                      EN PLACE DE PÉPINIÈRES
                    </i>
                  </a>
                </p>
                <p className="my-2">
                  <a className="mr-2">
                    <i className="fa fa-check-circle text-white fa-1x" />
                  </a>{" "}
                  <a className="text-white">
                    <i>
                      RENFORCEMENT DES CAPACITÉS DES ACTEURS SUR LES THÉMATIQUES
                      DE GESTION DES FORÊTS
                    </i>
                  </a>
                </p>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container">
          <div class="row justify-content-evenly">
            <div className="col-sm-7 align-self-center">
              <div className="">
                <h2 className="text-uppercase text-uppercase mt-3 text-primary">
                  <img
                    src="./images/agriculture.svg"
                    alt="wrappixel"
                    width={45}
                    className="me-1"
                  />{" "}
                  <i>Agriculture</i>
                </h2>
                <p className="text-primary">
                  Nous proposons des services de conseils agricoles à travers un
                  coaching de proximité et la conception et mise en œuvre de
                  plans d’affaires agricole. <br /> Pour ce faire, nous ciblons
                  nos interventions sur la base de l’indice de végétation par
                  différence normalisée (NDV)
                </p>
                <ul className="list-style-none pl-0 mt-0">
                  <p className="my-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>
                        AUDIT, RENFORCEMENT DES CAPACITÉS ET CONSEILS EN
                        AGROÉCONOMIE POUR LES SOCIÉTÉS ET COOPÉRATIVES AGRICOLES
                      </i>
                    </a>
                  </p>
                  <p className="my-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>APPUI À LA DIVERSIFICATION DES ACTIVITÉS AGRICOLES</i>
                    </a>
                  </p>
                  <p className="my-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>FORMATION SUR LES TECHNIQUES D’ÉLEVAGE</i>
                    </a>
                  </p>
                  <p className="my-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>
                        FORMATION DES PRODUCTEURS SUR LES BONNES PRATIQUES
                        AGRICOLES (BPA)
                      </i>
                    </a>
                  </p>
                  <p className="my-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>AMÉNAGEMENT DE PÉRIMÈTRES</i>
                    </a>
                  </p>
                  <p className="my-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>CARTOGRAPHIE DE L’OCCUPATION DU SOL</i>
                    </a>
                  </p>
                  <p className="mb-2 mt-0 mt-md-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>CALCULS DE BIOMASSE</i>
                    </a>
                  </p>
                  <p className="my-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>FERTILISATION AZOTÉE PAR VOIE AÉRIENNE</i>
                    </a>
                  </p>
                  <p className="my-2">
                    <a className="mr-2">
                      <i className="fa fa-check-circle text-primary fa-1x" />
                    </a>{" "}
                    <a className="text-primary">
                      <i>APPUI À A COMMERCIALISATION DES PRODUITS</i>
                    </a>
                  </p>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <img
                src="./images/1 (3).JPG"
                className="img-fluid"
                alt=""
                style={{ marginTop: 0 }}
              />
            </div>
          </div>
        </div>
      </section>
      <CTA />
    </>
  );
};

export default Services;
