import React from 'react'
import './index.css'

const Footer = () => {
  return (
    <>
  {/* Footer Start */}
  {/* <div className="container-fluid bg-footer bg-primary text-white mt-5">
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-8 col-md-6">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-12 pt-5 mb-5">
              <div className="d-flex mb-2">
                <i className="bi bi-geo-alt fa-2x text-white me-2" />
                <p className="text-white mb-2"
                style={{ fontSize : 20   }}
                >San-Pédro
                      <br />Quartier Jules Fery
                      <br />02 BP 211 Abidjan 02
                      </p>
              </div>
              
              <div className="d-flex mt-0">
              <a className='text-white'
                  className="btn btn-secondary btn-square rounded-circle me-2"
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/Dronek.CI/"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a className='text-white'
                  className="btn btn-secondary btn-square rounded-circle me-2"
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/dronek"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
                <a className='text-white'
                  className="btn btn-secondary btn-square rounded-circle"
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="mailto:info@dronek.net"
                >
                  <i className="bi bi-envelope" />
                </a>
              </div>
              <div className="d-flex ">
      
        </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
            <div className="ps-3">
                <i className="bi bi-envelope-open text-white me-2" />
                <a  className="text-white text-center" href='mailto:info@dronek.net'> info@dronek.net</a>
              </div>
              <div className="ps-3 mt-2">
              <i className="bi bi-phone text-white me-2" />
                <a  className="text-white" href='tel:+225 07 07 73 22 64'> +225 07 07 73 22 64</a><br />
                <i className="bi bi-phone text-white me-2" /> 
                <a  className="text-white" href='tel:+225 27 21 51 41 49'> +225 27 21 51 41 49</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
              <h4 className="text-uppercase text-white mb-4">Menu</h4>
              <div className="d-flex flex-column justify-content-start">
                <a  className="text-white mb-2" href="/">
                  <i className="bi bi-arrow-right text-white me-2" />
                  Acceuil
                </a>
                <a  className="text-white mb-2" href="/about">
                  <i className="bi bi-arrow-right text-white me-2" />
                  À propos de nous
                </a>
                <a  className="text-white mb-2" href="/service">
                  <i className="bi bi-arrow-right text-white me-2" />
                  Nos Services
                </a>
                <a  className="text-white mb-2" href="/réalisation">
                  <i className="bi bi-arrow-right text-white me-2" />
                  Nos Réalisations
                </a>
                <a  className="text-white" href="/contact">
                  <i className="bi bi-arrow-right text-white me-2" />
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid bg-dark text-white py-4">
    <div className="container text-center">
      <p className="">
        © 2023
        Tous les droits sont réservés par {" "}
        <a  className="text-secondary" 
        target="_blank" 
        rel="noopener noreferrer"
        href="https://diplomatechnology.com/">
        Diplomatech
        </a>
      </p>
    </div>
  </div> */}

<footer className="site-footer">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-6">
       <img src="images/logooo.png" alt="" className='img' />
        <p className=" text-white mt-3">
          Nous partageons avec nos collaborateurs des valeurs
          d’honnêteté, de confiance et d’équité. Notre amour de la nature
          et notre respect de l’environnement guident notre travail au
          quotidien sur le terrain et dans nos bureaux.
        </p>
      </div>
      <div className="col-xs-6 col-md-3">
        <h4 className='text-uppercase text-white'><i>Bureau</i></h4>
      <div className="location mt-4">
       <p className='text-white'>
        <i className='fa fa-map-pin me-2'/>Cocody en face  <br />
         de l'entrée principale <br />
        de l’hôtel Palm Club
       </p>
      </div>
      </div>
      <div className="col-xs-6 col-md-3">
        <h4 className='text-uppercase text-white'><i>Menu</i></h4>
        <ul className="footer-links">
          <li>
            <a className='text-uppercase text-white' href="/"><i>Accueil</i></a>
          </li>
          <li>
            <a className='text-uppercase text-white' href="/services"><i>Services</i></a>
          </li>
          <li>
            <a className='text-uppercase text-white' href="/realisations"><i>Réalisations</i></a>
          </li>
          <li>
            <a className='text-uppercase text-white' href="/about"><i>À propos</i></a>
          </li>
          <li>
            <a className='text-uppercase text-white' href="/contact"><i>Contact</i></a>
          </li>
        </ul>
      </div>
    </div>
    <hr />
  </div>
  <div className="container">
    <div className="row">
      <div className="col-md-8 col-sm-6 col-xs-12">
        <p className="text-white">
        Tous droits réservés © 2023 par
          <a className='text-white text-uppercase' 
          href="https://diplomatechnology.com/"
          target="_blank" 
          rel="noopener noreferrer"
          > <i>Diplomatech</i></a>.
        </p>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-12">
        <ul className="social-icons">
          <li>
            <a  className="facebook" 
            href="https://www.facebook.com/Dronek.CI/"
            target="_blank" 
                  rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a  className="twitter" 
            href="mailto:info@dronek.net"
            target="_blank" 
            rel="noopener noreferrer"
            >
              <i className="fa fa-envelope" />
            </a>
          </li>
          <li>
            <a  className="linkedin" 
            href="https://www.linkedin.com/company/dronek "
            target="_blank" 
            rel="noopener noreferrer"
            >
              <i className="bi bi-linkedin" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>


  
  {/* Footer End */}
</>

  )
}

export default Footer